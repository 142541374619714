<template>
    <div class="container-fluid p-0">
        <!--<div class="page-headermb">
            <h2 class="main-content-title">Welcome To Dashboard</h2>
        </div>-->
        <div class="row" style="margin-top:30px;">
            <div class="col-sm-12 col-md-7 col-lg-8">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="card custom-data-card">
                            <div class="card-body">
                                <div class="card-item">
                                    <div class="card-item-icon card-icon">
                                        <img class="img-fluid" src="../assets/img/team.png" alt="Team Icon" />
                                    </div>
                                    <div class="card-item-body">
                                        <div class="card-item-title mb-2">
                                            <h3 class="card-title mb-1">Teams</h3>
                                            <h4 class=" mb-0 text-muted">Active Projects</h4>
                                        </div>
                                        <div class="card-item-stat">
                                            <h4 class="m-0">{{ dashboardCount.team_project }}</h4>
                                            <!-- <h4 class="m-0">{{ teamProjects.length }}</h4> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <div class="card custom-data-card">
                            <div class="card-body">
                                <div class="card-item">
                                    <div class="card-item-icon card-icon">
                                        <img class="img-fluid" src="../assets/img/personal.png" alt="Team Icon" />
                                    </div>
                                    <div class="card-item-body">
                                        <div class="card-item-title mb-2">
                                            <h3 class="card-title mb-1">Personal</h3>
                                            <h4 class=" mb-0 text-muted">Active Projects</h4>
                                        </div>
                                        <div class="card-item-stat">
                                            <h4 class="m-0">{{ dashboardCount.personal_project }}</h4>
                                            <!-- <h4 class="m-0">{{ projects.length }}</h4> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="isAccountStatus">
                        <div class="card custom-data-card">
                            <div class="card-body">
                                <div class="card-item">
                                    <div class="card-item-icon card-icon">
                                        <img class="img-fluid" src="../assets/img/storage.png" alt="Team Icon" />
                                    </div>
                                    <div class="card-item-body">
                                        <div class="card-item-title mb-2">
                                            <h3 class="card-title mb-1">Account Status</h3>
                                            <h4 class=" mb-0 text-muted">Space Used</h4>
                                        </div>
                                        <div class="card-item-stat">
                                            <h4 class="m-0">0 GB out of 0.25 GB</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6" v-if="(isPricingPlan && activeSubscriptions.type =='personal')">
                        <div class="card custom-data-card">
                            <div class="card-body">
                                <div class="card-item">
                                    <div class="card-item-icon card-icon">
                                        <img class="img-fluid" src="../assets/img/pricing-plan.png" alt="Team Icon" />
                                    </div>
                                    <div class="card-item-body">
                                        <div class="card-item-title mb-2">
                                            <h3 class="card-title mb-1">Pricing Plan (Personal)</h3>
                                            <h4 class=" mb-0 text-muted">Current Plan</h4>
                                        </div>
                                        <div class="card-item-stat">
                                            <h4 class="m-0">{{ activeSubscriptions.name }}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-4">
                <div class="card custom-data-card">
                    <div class="card-body">
                    <div class="latest-team-members-section">
                        <h3 class="card-title mb-4">Latest Team Members</h3>
                        <div v-if="members.length > 0" class="single-team-member" v-for="member in members" :key="member.id">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="team-member-image">
                                    <img src="../assets/img/team-icon-placeholder.jpg" alt=""/>
                                </div>
                                <div class="team-member-name">
                                    <h4>{{ member.user.first_name + " " + member.user.last_name }}</h4>
                                    <p class="mb-1">{{ member.user.email }}</p>
                                    <span class="badge rounded-pill bg-success">{{ member.team.name }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-if="members.length <= 0" class="single-team-member">
                            <strong>There is no team member currently.</strong>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <!--<div class="col-md-6 col-xxl-4">
                <div class="card p-4">
                    <div class="user-card d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <img
                                v-if="imageShow !== null"
                                :src="`${baseUrl}`+imageShow"
                                class="rounded-circle me-3 img-fluid"
                                style="width: 120px; height: 120px"
                                :alt="authUser.first_name + ' ' + authUser.last_name"
                            />
                            <img
                                v-else
                                class="rounded-circle me-3 img-fluid"
                                style="width: 120px; height: 120px"
                                src="../assets/img/no-avatar.png"
                                alt=""
                            />
                            <div>
                                <h2>{{ authUser.first_name + " " + authUser.last_name }}</h2>
                                <p>{{ authUser.email }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
            <!-- <div class="col-md-6 col-xxl-4" v-if="paymentMethods.length">
                <div class="card p-4">
                    <div class="payment-card">
                        <h2>Payment Method</h2>

                        <div class="payment-input mb-3">
                          <svg width="60" height="28"
                               viewBox="0 0 141.732 141.732" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#2566af">
                            <path d="M62.935 89.571h-9.733l6.083-37.384h9.734zM45.014 52.187L35.735 77.9l-1.098-5.537.001.002-3.275-16.812s-.396-3.366-4.617-3.366h-15.34l-.18.633s4.691.976 10.181 4.273l8.456 32.479h10.141l15.485-37.385H45.014zM121.569 89.571h8.937l-7.792-37.385h-7.824c-3.613 0-4.493 2.786-4.493 2.786L95.881 89.571h10.146l2.029-5.553h12.373l1.14 5.553zm-10.71-13.224l5.114-13.99 2.877 13.99h-7.991zM96.642 61.177l1.389-8.028s-4.286-1.63-8.754-1.63c-4.83 0-16.3 2.111-16.3 12.376 0 9.658 13.462 9.778 13.462 14.851s-12.075 4.164-16.06.965l-1.447 8.394s4.346 2.111 10.986 2.111c6.642 0 16.662-3.439 16.662-12.799 0-9.72-13.583-10.625-13.583-14.851.001-4.227 9.48-3.684 13.645-1.389z"/></g>
                            <path d="M34.638 72.364l-3.275-16.812s-.396-3.366-4.617-3.366h-15.34l-.18.633s7.373 1.528 14.445 7.253c6.762 5.472 8.967 12.292 8.967 12.292z" fill="#e6a540"/>
                            <path fill="none" d="M0 0h141.732v141.732H0z"/>
                          </svg>
                            <input type="text" :placeholder="`**** **** **** ${paymentMethods[0].card.last4}`"/>
                        </div>
                        <el-button type="success" @click.prevent="$router.push('/pricing')">ADD A NEW CARD</el-button>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title mb-4">Projects</h3>
                        <el-tabs v-model="activeName" @tab-click="handleClick" class="custom-tab">
                            <el-tab-pane label="Personal Projects" name="personal">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Project Name</th>
                                                <th>Start Date</th>
                                                <th>Deadline</th>
                                                <th>Your Role</th>
                                                <th>Collaborators</th>
                                                <th>Docs Collected</th>
                                                <th>Docs Approved</th>
                                            </tr>
                                        </thead>
                                        <tbody class="position-relative">
                                            <div v-if="firstLoading" class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white" style="z-index: 10000;">
                                                <i style="font-size: 25px" class="el-icon-loading text-black"></i>
                                            </div>
                                            <tr v-for="project in projects" :key="project.id">
                                                <td v-if="project.project_type == 'PUBLIC'">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 21"
                                                    >
                                                        <g id="lock-open" transform="translate(-4 -1)">
                                                            <rect
                                                                id="Rectangle_2"
                                                                data-name="Rectangle 2"
                                                                width="14"
                                                                height="10"
                                                                rx="2"
                                                                transform="translate(5 11)"
                                                                fill="none"
                                                                stroke="#2f80ed"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                            />
                                                            <circle
                                                                id="Ellipse_13"
                                                                data-name="Ellipse 13"
                                                                cx="1"
                                                                cy="1"
                                                                r="1"
                                                                transform="translate(11 15)"
                                                                fill="none"
                                                                stroke="#2f80ed"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                            />
                                                            <path
                                                                id="Path_3"
                                                                data-name="Path 3"
                                                                d="M8,11V6a4,4,0,0,1,8,0"
                                                                fill="none"
                                                                stroke="#2f80ed"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                            />
                                                        </g>
                                                    </svg>
                                                </td>
                                                <td v-else>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15.647"
                                                        height="16"
                                                        viewBox="0 0 15.647 19"
                                                    >
                                                        <path
                                                            id="git-repository-private-line"
                                                            d="M5.608,9.143V18.19h11.3V9.143Zm10.431-1.81h1.739a.888.888,0,0,1,.869.9V19.1a.888.888,0,0,1-.869.9H3.869A.888.888,0,0,1,3,19.1V8.238a.888.888,0,0,1,.869-.9H5.608v-.9A5.325,5.325,0,0,1,10.824,1a5.325,5.325,0,0,1,5.216,5.429Zm-1.739,0v-.9A3.55,3.55,0,0,0,10.824,2.81,3.55,3.55,0,0,0,7.346,6.429v.9ZM6.477,10.048H8.216v1.81H6.477Zm0,2.714H8.216v1.81H6.477Zm0,2.714H8.216v1.81H6.477Z"
                                                            transform="translate(-3 -1)"
                                                            fill="#2f80ed"
                                                        />
                                                    </svg>
                                                </td>
                                                <td>{{ project.title }}</td>
                                                <td>
                                                    {{ project.start_date }}
                                                </td>
                                                <td>
                                                    {{ project.end_date_first }}
                                                </td>
                                                <td>
                                                <span class="badge rounded-pill bg-success">
                                                {{ getUserType(project.my_roles) }}
                                                </span>
                                                </td>
                                                <td>
                                                    {{ project.total_collaborators }}
                                                </td>
                                                <td>
                                                    {{ project.collected_documents }}
                                                </td>
                                                <td>
                                                    {{ project.approved_documents }}
                                                </td>
        <!--                                        <td id="fixedActionArea">-->
        <!--                                            <button class="me-2">-->
        <!--                                                <svg-->
        <!--                                                    id="Group_47"-->
        <!--                                                    data-name="Group 47"-->
        <!--                                                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                                                    width="31"-->
        <!--                                                    height="31"-->
        <!--                                                    viewBox="0 0 31 31"-->
        <!--                                                >-->
        <!--                                                    <g id="eye-fill" transform="translate(10 12)">-->
        <!--                                                        <path-->
        <!--                                                            id="Path_1"-->
        <!--                                                            data-name="Path 1"-->
        <!--                                                            d="M9.016,7.258A1.758,1.758,0,1,1,7.258,5.5,1.758,1.758,0,0,1,9.016,7.258Z"-->
        <!--                                                            transform="translate(-1.633 -3.391)"-->
        <!--                                                            fill="#00b754"-->
        <!--                                                        />-->
        <!--                                                        <path-->
        <!--                                                            id="Path_2"-->
        <!--                                                            data-name="Path 2"-->
        <!--                                                            d="M0,6.367S2.109,2.5,5.625,2.5,11.25,6.367,11.25,6.367s-2.109,3.867-5.625,3.867S0,6.367,0,6.367ZM5.625,8.828A2.461,2.461,0,1,0,3.164,6.367,2.461,2.461,0,0,0,5.625,8.828Z"-->
        <!--                                                            transform="translate(0 -2.5)"-->
        <!--                                                            fill="#00b754"-->
        <!--                                                            fill-rule="evenodd"-->
        <!--                                                        />-->
        <!--                                                    </g>-->
        <!--                                                    <circle-->
        <!--                                                        id="Ellipse_62"-->
        <!--                                                        data-name="Ellipse 62"-->
        <!--                                                        cx="15.5"-->
        <!--                                                        cy="15.5"-->
        <!--                                                        r="15.5"-->
        <!--                                                        fill="#00b754"-->
        <!--                                                        opacity="0.1"-->
        <!--                                                    />-->
        <!--                                                </svg>-->
        <!--                                            </button>-->
        <!--                                            <button class="me-2">-->
        <!--                                                <svg-->
        <!--                                                    id="Group_48"-->
        <!--                                                    data-name="Group 48"-->
        <!--                                                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                                                    width="31"-->
        <!--                                                    height="31"-->
        <!--                                                    viewBox="0 0 31 31"-->
        <!--                                                >-->
        <!--                                                    <circle-->
        <!--                                                        id="Ellipse_62"-->
        <!--                                                        data-name="Ellipse 62"-->
        <!--                                                        cx="15.5"-->
        <!--                                                        cy="15.5"-->
        <!--                                                        r="15.5"-->
        <!--                                                        fill="#00b754"-->
        <!--                                                        opacity="0.1"-->
        <!--                                                    />-->
        <!--                                                    <g id="Edit" transform="translate(10.075 10.075)">-->
        <!--                                                        <path-->
        <!--                                                            id="Edit-2"-->
        <!--                                                            data-name="Edit"-->
        <!--                                                            d="M6.8,10.851a.617.617,0,0,1,0-1.234h3.442a.617.617,0,0,1,0,1.234ZM.511,10.595l-.48-2.08A1.285,1.285,0,0,1,.275,7.432L4.029,2.573a.188.188,0,0,1,.256-.032L5.865,3.8a.509.509,0,0,0,.39.111.569.569,0,0,0,.492-.629.63.63,0,0,0-.2-.382L5.014,1.666a.228.228,0,0,1-.038-.317L5.571.577A1.557,1.557,0,0,1,7.854.421l.889.707a1.845,1.845,0,0,1,.69,1.057A1.446,1.446,0,0,1,9.14,3.416l-5.3,6.849a1.269,1.269,0,0,1-.985.492l-2.11.026A.241.241,0,0,1,.511,10.595Z"-->
        <!--                                                            fill="#00b754"-->
        <!--                                                        />-->
        <!--                                                    </g>-->
        <!--                                                </svg>-->
        <!--                                            </button>-->
        <!--                                            <button class="me-2">-->
        <!--                                                <svg-->
        <!--                                                    id="Group_50"-->
        <!--                                                    data-name="Group 50"-->
        <!--                                                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                                                    width="31"-->
        <!--                                                    height="31"-->
        <!--                                                    viewBox="0 0 31 31"-->
        <!--                                                >-->
        <!--                                                    <circle-->
        <!--                                                        id="Ellipse_61"-->
        <!--                                                        data-name="Ellipse 61"-->
        <!--                                                        cx="15.5"-->
        <!--                                                        cy="15.5"-->
        <!--                                                        r="15.5"-->
        <!--                                                        fill="#eb5757"-->
        <!--                                                        opacity="0.1"-->
        <!--                                                    />-->
        <!--                                                    <path-->
        <!--                                                        id="copy-24"-->
        <!--                                                        d="M3.972,3A.972.972,0,0,0,3,3.972V9.25a.973.973,0,0,0,.972.972h.833a.417.417,0,1,0,0-.833H3.972a.139.139,0,0,1-.139-.139V3.972a.139.139,0,0,1,.139-.139H9.25a.139.139,0,0,1,.139.139v.833a.417.417,0,1,0,.833,0V3.972A.972.972,0,0,0,9.25,3ZM6.75,5.778a.972.972,0,0,0-.972.972v5.278A.973.973,0,0,0,6.75,13h5.278A.972.972,0,0,0,13,12.028V6.75a.972.972,0,0,0-.972-.972Zm-.139.972a.139.139,0,0,1,.139-.139h5.278a.139.139,0,0,1,.139.139v5.278a.139.139,0,0,1-.139.139H6.75a.139.139,0,0,1-.139-.139Z"-->
        <!--                                                        transform="translate(8 8)"-->
        <!--                                                        fill="#00b754"-->
        <!--                                                        fill-rule="evenodd"-->
        <!--                                                    />-->
        <!--                                                </svg>-->
        <!--                                            </button>-->
        <!--                                            <button>-->
        <!--                                                <svg-->
        <!--                                                    id="Group_52"-->
        <!--                                                    data-name="Group 52"-->
        <!--                                                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                                                    width="31"-->
        <!--                                                    height="31"-->
        <!--                                                    viewBox="0 0 31 31"-->
        <!--                                                >-->
        <!--                                                    <path-->
        <!--                                                        id="stop-circle-line"-->
        <!--                                                        d="M8.958,15.917a6.958,6.958,0,1,1,6.958-6.958A6.958,6.958,0,0,1,8.958,15.917Zm0-1.392A5.567,5.567,0,1,0,3.392,8.958,5.567,5.567,0,0,0,8.958,14.525ZM6.871,6.871h4.175v4.175H6.871Z"-->
        <!--                                                        transform="translate(7 7)"-->
        <!--                                                        fill="#00b754"-->
        <!--                                                    />-->
        <!--                                                    <circle-->
        <!--                                                        id="Ellipse_61"-->
        <!--                                                        data-name="Ellipse 61"-->
        <!--                                                        cx="15.5"-->
        <!--                                                        cy="15.5"-->
        <!--                                                        r="15.5"-->
        <!--                                                        fill="#eb5757"-->
        <!--                                                        opacity="0.1"-->
        <!--                                                    />-->
        <!--                                                </svg>-->
        <!--                                            </button>-->
        <!--                                        </td>-->
                                            </tr>
                                            <tr v-if="projects.length <= 0">
                                                <td colspan="9" class="text-danger text-center">No Project Available</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Team Projects" name="team">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Project Name</th>
                                            <th>Start Date</th>
                                            <th>Deadline</th>
                                            <th>Your Role</th>
                                            <th>Collaborators</th>
                                            <th>Docs Collected</th>
                                            <th>Docs Approved</th>
                                            <!--<th id="fixedActionArea">Actions</th>-->
                                        </tr>
                                        </thead>
                                        <tbody class="position-relative">
                                            <div v-if="firstLoading" class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white mt-5" style="z-index: 10000;">
                                                <i style="font-size: 25px" class="el-icon-loading text-black"></i>
                                            </div>
                                            <tr v-for="project in teamProjects" :key="project.id">
                                                <td v-if="project.project_type == 'PUBLIC'">
                                                    <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 21">
                                                        <g id="lock-open" transform="translate(-4 -1)">
                                                            <rect
                                                                id="Rectangle_2"
                                                                data-name="Rectangle 2"
                                                                width="14"
                                                                height="10"
                                                                rx="2"
                                                                transform="translate(5 11)"
                                                                fill="none"
                                                                stroke="#2f80ed"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                            />
                                                            <circle
                                                                id="Ellipse_13"
                                                                data-name="Ellipse 13"
                                                                cx="1"
                                                                cy="1"
                                                                r="1"
                                                                transform="translate(11 15)"
                                                                fill="none"
                                                                stroke="#2f80ed"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                            />
                                                            <path
                                                                id="Path_3"
                                                                data-name="Path 3"
                                                                d="M8,11V6a4,4,0,0,1,8,0"
                                                                fill="none"
                                                                stroke="#2f80ed"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                            />
                                                        </g>
                                                    </svg>
                                                </td>
                                                <td v-else>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.647" height="16" viewBox="0 0 15.647 19">
                                                        <path
                                                            id="git-repository-private-line"
                                                            d="M5.608,9.143V18.19h11.3V9.143Zm10.431-1.81h1.739a.888.888,0,0,1,.869.9V19.1a.888.888,0,0,1-.869.9H3.869A.888.888,0,0,1,3,19.1V8.238a.888.888,0,0,1,.869-.9H5.608v-.9A5.325,5.325,0,0,1,10.824,1a5.325,5.325,0,0,1,5.216,5.429Zm-1.739,0v-.9A3.55,3.55,0,0,0,10.824,2.81,3.55,3.55,0,0,0,7.346,6.429v.9ZM6.477,10.048H8.216v1.81H6.477Zm0,2.714H8.216v1.81H6.477Zm0,2.714H8.216v1.81H6.477Z"
                                                            transform="translate(-3 -1)"
                                                            fill="#2f80ed"
                                                        />
                                                    </svg>
                                                </td>
                                                <td>{{ project.title }}</td>
                                                <td>
                                                    {{ project.start_date }}
                                                </td>
                                                <td>
                                                    {{ project.end_date_first }}
                                                </td>
                                                <td>
                                                    <span class="badge rounded-pill bg-success">
                                                    {{ getUserType(project.my_roles) }}
                                                    </span>
                                                </td>
                                                <td>
                                                    {{ project.total_collaborators }}
                                                </td>
                                                <td>
                                                    {{ project.collected_documents }}
                                                </td>
                                                <td>
                                                    {{ project.approved_documents }}
                                                </td>
                                            </tr>
                                            <tr v-if="teamProjects.length <= 0">
                                                <td colspan="9" class="text-danger text-center">No Project Available</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>

       <!-- <div class="col-lg-9 col-md-9">-->
    <!--        <h4 class="pt-3 pb-3 dashboard-txt">Dashboard</h4>-->
    <!--        <div class="row custom-margin">-->
    <!--            <div class="col-lg-4 col-md-4">-->
    <!--                <div class="custom-card-new" @click="goToTeamDashboard()">-->
    <!--                    <img src="../../assets/img/teams.jpg" class="card-icon-new" alt="">-->
    <!--                    <p class="card-txt">Teams</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="col-lg-4 col-md-4">-->
    <!--                <div class="custom-card-new large-screen-card-two" @click="goToPersonalProjects()">-->
    <!--                    <img src="../../assets/img/personal.jpg" class="card-icon-new" alt="">-->
    <!--                    <p class="card-txt">Personal Projects</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            &lt;!&ndash; <div class="col-lg-4 col-md-4">-->
    <!--                <div class="custom-card-new large-screen-card-three" @click="goToExternalProjects()">-->
    <!--                    <img src="../../assets/img/external.jpg" class="card-icon-new" alt="">-->
    <!--                    <p class="card-txt">External Public <br> Projects (ID Required)</p>-->
    <!--                </div>-->
    <!--            </div> &ndash;&gt;-->
    <!--        </div>-->
    <!--        <div class="row custom-margin">-->
    <!--            <div class="col-lg-4 col-md-4">-->
    <!--                <div class="custom-card-new mg-top" @click="goToProfile()">-->
    <!--                    <img src="../../assets/img/profile.png" class="card-icon-new" alt="">-->
    <!--                    <p class="card-txt">Profile</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="col-lg-4 col-md-4">-->
    <!--                <div class="custom-card-new mg-top large-screen-card-two" @click="goToPricingPlans()">-->
    <!--                    <img src="../../assets/img/pricing.jpg" class="card-icon-new" alt="">-->
    <!--                    <p class="card-txt">Pricing Plans</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            <div class="col-lg-4 col-md-4">-->
    <!--                <div class="custom-card-new mg-top large-screen-card-three" @click="goToAccountStatus()">-->
    <!--                    <img src="../../assets/img/account_status.jpg" class="card-icon-new" alt="">-->
    <!--                    <p class="card-txt">Account Status</p>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
       <!-- </div> -->
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import {EventBus} from "../event-bus";
import { Notification } from 'element-ui';


export default {
    name: "Dashboard",
    data() {
        return {
            activeName: "personal",
            dashboardCount: {
                personal_project: 0,
                team_project: 0,
            },
            members: [],
            projects: [],
            teamProjects: [],
            paymentMethods: [],
            imageShow: null,
            baseUrl: `${process.env.VUE_APP_IMAGE_BASE_URL}`,
            isAccountStatus: false,
            isPricingPlan: false,
            activeSubscriptions: {},
            firstLoading: false,
        };
    },
    computed: {
        ...mapGetters("auth", ["authUser"]),
    },
    methods: {
        getProjectCount() {
            axios
                .get(`/dashboard/counts`)   
                .then((res) => {
                    // console.log(res.data);
                    this.dashboardCount = res.data;
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        latestTeamMembers() {
            axios
                .get(`/dashboard/latest-team-members`)
                .then((res) => {
                    this.members = res.data.members;
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        getPersonalProjects(page = 1) {
            this.firstLoading = true;
            axios.get(`/projects?page=${page}&status=ACTIVE`).then((res) => {
                this.projects = res.data.data;
            })
            .finally(() =>{
                this.firstLoading = false;
            });
        },
        getTeamProjects() {
            this.firstLoading = true;
            axios.get(`/teams/projects`).then((res) => {
                this.teamProjects = res.data.data;
            })
            .finally(() =>{
                this.firstLoading = false;
            });
        },
        viewDetails(project) {
            this.showDetails = true;
            this.getUserType(project.my_roles);
            if (
                project.my_roles.some(
                    (role) =>
                        role.user_type === "HOST" || role.user_type === "SUPPORT_STAFF"
                )
            ) {
                this.hostShow = true;
                this.projectDetails = project;
            } else if (
                project.my_roles.some((role) => role.user_type === "COLLABORATOR")
            ) {
                this.hostShow = false;
                this.projectDetails = project;
            }
        },

        getUserType(myRoles) {
            let roleTxt = "";
            let userTypes = {
                HOST: "Host",
                COLLABORATOR: "Collaborator",
                SUPPORT_STAFF: "Support Staff",
                TEAM_MEMBER: "Team Member"
            };
            myRoles.forEach((role, index) => {
                roleTxt += userTypes[role.user_type];
                roleTxt += role.scopes ? ": " + role.scopes : "";
                roleTxt += index + 1 < myRoles.length ? ", " : "";
            });
            return roleTxt;
        },
        handleClick(val) {
            if (val.name === 'team') {
                this.firstLoading = true;
                try{
                    this.getTeamProjects();
                }
                finally{
                    this.firstLoading = false;
                }
            }
        },
        goToTeamDashboard() {
            this.$router.push({path: "/team/dashboard"});
        },
        goToPersonalProjects() {
            this.$router.push({path: "/personal-project/dashboard"});
        },
        goToExternalProjects() {
            this.$router.push({path: "/external-public-project/dashboard"});
        },
        goToProfile() {
            this.$router.push({path: "/profile/create"});
        },
        goToPricingPlans() {
            this.$router.push({
                path: "/pricing/plans/personal",
                query: {
                    plan: "annual",
                },
            });
        },
        goToAccountStatus() {
            this.$router.push({path: "/account-status/space-used"});
        },
        getStripPaymentMethod() {
            axios.get("/subscriptions/payment-method")
                .then((response) => {
                    this.paymentMethods = response.data;
                })
        },
        getAuthUser() {
            axios.get('/account/profile').then((response) => {
                this.imageShow = response.data.data.profile_picture
            })
        },
        checkSubscription() {
            axios.get('/subscriptions/subscribed')
            .then(res => {
                this.activeSubscriptions = res.data.activeSubscriptions;
                this.isPricingPlan = (!this.activeSubscriptions || Object.keys(this.activeSubscriptions).length === 0) ? false : true;
            })
        },


    },
    mounted() {
        // it's used because we are not using vuex store
        // কারন বায়ার খাটাইস, সময় দেয় নাই তেমন একটা। সময় পেলে ভিউ এক্স ব্যবহার করবো
        EventBus.$on("profile-avatar-updated", () => {
            this.getAuthUser();
        });
        if (this.$route.query.welcome) {
          Notification.success('Welcome back!');
        }
        this.getProjectCount();
        this.latestTeamMembers();
        this.getPersonalProjects();
        this.getStripPaymentMethod();
        this.getAuthUser();
        this.checkSubscription();
    },
};
</script>

<style scoped lang="scss">
.payment-card {
    h2 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .payment-input {
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            width: 80%;
            outline: none;
            border: none;
        }
    }
}
/*------- New CSS --------*/



.user-card {
    padding: 12px 0;

    h2 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }

    p {
        font-weight: 500;
        font-size: 12px;
        color: #a0aec0;
        margin: 0;
    }
}

.summary-card {
    text-align: center;

    h2 {
        font-size: 14px;
        font-weight: 600;
    }

    p {
        font-weight: 500;
        font-size: 0.75rem;
        color: #a0aec0;
    }
}

@media (max-width: 576px) {
    .summary-card {
        h2 {
            font-size: 16px;
        }
    }
    .user-card {
        img {
            width: 90px !important;
            height: 90px !important;
        }
    }
}

//@media only screen and (max-width: 600px) and (min-width: 400px) {
//    .user-card {
//        flex-direction: column;
//        align-items: unset !important;
//
//        img {
//            width: 80px !important;
//            height: 80px !important;
//            margin-bottom: 15px;
//        }
//    }
//}
</style>
